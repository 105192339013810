import {Question} from "./question.model";
import {Guest} from "./guest.model";
import {AddSemantic} from './extends/semantic.extend';
import {Satisfaction} from './satisfaction.model';

export class Survey {
  id: number;
  sent_date: Date | any;
  open_date: Date | any;
  comments: CommentSurvey[] = [];
  guest:Guest | any= new Guest();
  answer:AnswerSurvey;
  question: Question[] | any=[];
  nps:string;
  satisfaction:Satisfaction;
  score:number=0;
  cases: any[] = [];
  deleted=false;
  version = 1;
  tripadvisor: boolean
  product_id: number
  origin?: string;
  origin_name: string;
  integration_channel: {id: number, source: string}
  survey_name: any
  managed: any;
  contacted: any;
  language_answer: any;
  survey_id?: number;
  answered_date?: string;
  customer_id?: number;

  static createFromApi(object:any, forCommentsFeed = false) {
    return !forCommentsFeed ?
      (new Survey()).createFromApi(object) :
      (new Survey()).createFromApiForCommentsFeed(object);
  }

  constructor(guest?: Guest, )
  constructor(guest?: Guest, sentDate?: Date, openDate?: Date)
  constructor(guest?: Guest, sentDate?: Date, openDate?: Date, comment?: string, commentDate?: Date, question?: Question[]) {
    this.sent_date = sentDate;
    this.open_date = openDate;
    this.guest = guest;
    this.question = question;
  }

  setSurveyName(translates) {
    this.survey_name = translates
  }

  createFromApiForCommentsFeed(data) {
    this.id = data.answered_survey.survey_id;
    this.guest = Guest.createFromApi(data.guest);
    this.sent_date = data.sent_date ? new Date(data.sent_date) : new Date(data.answered_survey.answered_date);
    this.open_date = data.open_date ? new Date(data.open_date) : new Date(data.answered_survey.open_date);
    this.nps = data.answered_survey && data.answered_survey.nps.nps;
    this.version = data.version;
    this.origin_name = data.answered_survey.origin
    this.integration_channel = data.guest?.integration_channel

    const comment = data.comments || (data.answered_survey && data.answered_survey?.comments) || []
    this.comments = comment.map((comment) => {
      return new CommentSurvey(
        comment.id,
        comment.text,
        data.semantic_areas || (data.semantic_info && data.semantic_info.semantic_areas),
        data.semantic_rating || (data.semantic_info && data.semantic_info.semantic_rating),
        comment.alternative_id
      )
    })
    this.satisfaction = (data.answered_survey && data.answered_survey.satisfaction) || new Satisfaction();
    this.answer = new AnswerSurvey(
      data.answered_survey && data.answered_survey.id,
      data.answered_survey && data.answered_survey.answered_date,
    )
    this.cases = data.cases ? data.cases : data.answered_survey.cases;
    return this;
  }

  createFromApi(data){
    this.id = data.answered_survey ? data.answered_survey.survey_id : data.survey_id;
    this.guest = Guest.createFromApi(data.guest);
    this.sent_date = data.sent_date ? new Date(data.sent_date) : new Date(data.answered_survey.sent_date);
    this.open_date = data.open_date ? new Date(data.open_date) : new Date(data.answered_survey.open_date);
    this.nps = data.answered_survey && data.answered_survey.nps;
    this.version = data.version;
    this.origin_name = data.answered_survey?.origin
    this.integration_channel = data.guest?.integration_channel

    const comment = data.comment || (data.answered_survey && data.answered_survey.comment)
    this.comments = !comment ? [] : [
      new CommentSurvey(
        comment.id,
        comment.text,
        data.semantic_areas || (data.semantic_info && data.semantic_info.semantic_areas),
        data.semantic_rating || (data.semantic_info && data.semantic_info.semantic_rating),
        comment.alternative_id
      )
    ];
    this.satisfaction = (data.answered_survey && data.answered_survey.satisfaction) || new Satisfaction();
    this.answer = new AnswerSurvey(
      data.answered_survey && data.answered_survey.id,
      data.answered_survey && (data?.answered_survey?.answered_date || data.answered_survey.date),
    )
    this.cases = data.cases ? data.cases : data.answered_survey.cases;
    return this;
  }
}

export class AnswerSurvey{
  date:Date;
  id:number;
  constructor(id, date){
    this.id           = id;
    this.date         = date;
  }
}

export class CommentSurvey  extends AddSemantic{
  text:string;
  id;
  translate;
  alternative_id;
  constructor(id,text,semantic_areas,semantic_rating,alternative_id){
    super();
    this.text = text;
    this.id   = id;
    this.setExcerpt(this.text);
    this.semanticAreas   = semantic_areas
    this.semanticRating  = semantic_rating
    this.themeList        = this.getThemes();
    this.alternative_id = alternative_id;
  }


}
