<ng-container *ngFor="let type of options">
  <button
    (click)="handleSelectedOption(type)"
    type="button"
    class="tw-shadow-md tw-bg-gray-100 tw-text-sm tw-font-normal tw-px-4 tw-py-1 tw-text-gray-400 first:tw-rounded-l-lg last:tw-rounded-r-lg focus:tw-outline-none"
    [ngClass]="{ 'tw-bg-white !tw-text-black': selectedType === type }"
  >
    {{ 'answer_survey.' + type | translate }}
  </button>
</ng-container>
