<article #createCaseContainer class="tw-flex tw-flex-col tw-relative tw-px-8 tw-pt-8 tw-pb-7 tw-overflow-y-auto tw-max-h-[80vh]">
  <div class="tw-flex tw-flex-col">
    <div *ngIf="item" class="tw-mb-4">
      <section *ngIf="isFromProduct && item">
        <mh-core-lib-product-for-case
          [item]="item"
          [type]="data?.associated"
          [language]="currentLanguage"
          [product]="data?.product"
          [createCase]="'true'"
          [source]="'create'"
        ></mh-core-lib-product-for-case>
      </section>
    </div>

    <form class="tw-w-full tw-flex tw-flex-col" [formGroup]="caseForm" (ngSubmit)="submit()">

      <article class="tw-mb-5">
        <mh-core-lib-text-input
          [placeholder]="'core-lib.input.dialog_cases.title' | translate"
          [keyControl]="'title'"
          [formGroup]="caseForm"
          [maxLength]="maxTitleLength"
          [className]="'tw-font-semibold tw-text-xl !tw-text-black tw-border-gray-400 !tw-font-[Rubik]'"
        ></mh-core-lib-text-input>
      </article>

      <article class="tw-mb-3">
        <mh-core-lib-textarea-input
          [placeholder]="'core-lib.input.dialog_cases.description' | translate"
          [keyControl]="'description'"
          [formGroup]="caseForm"
          [withMentions]="true"
          [users]="assignOptions"
          [maxLength]="maxDescriptionLength"
          [rows]="6"
          [inputClass]="'!tw-font-[Rubik] tw-border-gray-400'"
          (mentionStateChanged)="handleMentionState($event)"
        ></mh-core-lib-textarea-input>
      </article>

      <article class="tw-mb-5">
        <mh-core-lib-file-uploader
          [customerId]="currentCustomerId"
          (filesUploaded)="handleFilesUploaded($event)"
          (uploadingFilesProgress)="uploadingFiles = $event"
        ></mh-core-lib-file-uploader>
      </article>

      <article class="tw-mb-5" *ngIf="!isFromProduct">
        <mh-core-lib-autocomplete-input
          class="tw-w-full"
          [options]="onsiteGuests"
          [openBox]="false"
          [filterFn]="autocompleteOnsiteGuestsFilterFn"
          [displayOptionsFn]="displayOnsiteGuestsOptions"
          [placeholder]="'core-lib.input.dialog_cases.guest_or_room' | translate"
          [showIcon]="false"
          customClassInput="!tw-pl-5 !tw-font-[Rubik] placeholder:!tw-text-main-small tw-border-gray-400"
          customClassBox="tw-absolute tw-z-10"
          customClassOptions="tw-font-[Rubik] tw-font-normal tw-text-base tw-text-black"
          (optionSelected)="handleGuestOrRoomOption($event)"
        ></mh-core-lib-autocomplete-input>
      </article>

      <article class="tw-mb-5 ">
        <div class="tw-flex">
          <mh-core-lib-autocomplete-input
            class="tw-w-full"
            [options]="assignOptions"
            [openBox]="false"
            [filterFn]="assignSelected === 'person' ? autocompleteFilterFn : autocompleteTeamsFilterFn"
            [displayOptionsFn]="assignSelected === 'person' ? displayUsersOptions : displayTeamsOptions"
            [placeholder]="'core-lib.input.dialog_cases.assign_to' | translate"
            [showIcon]="false"
            [clearControl]="clearAssignedAutocompleteControl"
            customClassInput="!tw-pl-5 !tw-font-[Rubik] tw-rounded-tr-none tw-rounded-br-none placeholder:!tw-text-main-small tw-border-gray-400 !tw-min-w-fit"
            customClassBox="tw-absolute tw-z-10"
            customClassOptions="tw-font-[Rubik] tw-font-normal tw-text-base tw-text-black"
            (optionSelected)="handleAssignOption($event)"
            (writtenText)="handleEmptyAssignInput($event)"
          ></mh-core-lib-autocomplete-input>
          <div
            class="tw-flex tw-justify-between tw-w-[30%] tw-max-h-[2.7rem] tw-flex-none tw-cursor-pointer tw-items-center tw-bg-stone-100 tw-text-gray-400 tw-font-medium tw-rounded-r-lg tw-px-0 sm:tw-px-4 tw-border tw-border-gray-400 tw-border-l-0"
            (click)="handleAssignSelectorClick()"
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
          >
            <ng-container *ngIf="isMobile; else desktopSelector">
              <div (click)="handleAssignType('person')" [ngClass]="{'tw-bg-sky-100': assignSelected === 'person'}" class="tw-w-full tw-h-full tw-grid tw-content-center tw-justify-center tw-border-r tw-border-gray-400 tw-cursor-pointer">
                <i class="fal fa-user tw-text-main-blue"></i>
              </div>
              <div (click)="handleAssignType('team')" [ngClass]="{'tw-bg-sky-100': assignSelected === 'team'}" class="tw-w-full tw-h-full tw-grid tw-content-center tw-justify-center tw-cursor-pointer tw-rounded-r-lg">
                <i class="fal fa-users tw-text-main-blue"></i>
              </div>
            </ng-container>

            <ng-template #desktopSelector>
              <span>{{ 'core-lib.input.dialog_cases.' + assignSelected | translate }}</span>
              <i class="fal fa-chevron-down"></i>
            </ng-template>
          </div>

          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="assignOpen"
            [cdkConnectedOverlayPanelClass]="'!tw-mt-2'"
            (overlayOutsideClick)="assignOpen = false"
          >
            <ul class="tw-bg-stone-100 tw-shadow-1 tw-relative tw-p-3 tw-rounded-r-lg tw-border tw-border-gray-400 tw-w-40">
              <li class="tw-py-1 tw-cursor-pointer" *ngFor="let item of assignTypes" (click)="handleAssignType(item)">
                <button type="button" class="hover:tw-text-main-orange tw-transition-all focus:tw-outline-0">
                  <span class="tw-font-[Rubik] tw-font-medium tw-text-gray-400">{{ 'core-lib.input.dialog_cases.' + item | translate }}</span>
                </button>
              </li>
            </ul>
          </ng-template>
        </div>

        <ng-container>
          <p *ngIf="assignSelected === 'team' && assignOptions.length === 0" class="tw-text-red-500 tw-text-center tw-text-sm tw-mt-1">{{ 'cases.create_modal.no_teams' | translate }}</p>
        </ng-container>
      </article>

      <article class="tw-mb-5 tw-flex" formGroupName="time_type">
        <mh-core-lib-text-input
          class="tw-w-full"
          [type]="timeSelected !== 'date' ? 'number' : 'date'"
          [minDate]="timeSelected === 'date' ? todayDate : null"
          [placeholder]="('core-lib.input.dialog_cases.time_to_resolve' | translate) + (!flagConditionalExpired ? ' (' + ('commons.optional' | translate) + ')' : '')"
          [formGroup]="caseForm.get('time_type')"
          [keyControl]="'total'"
          [className]="'tw-border-gray-400 tw-rounded-tr-none tw-rounded-br-none !tw-font-[Rubik]'"
          [clearControl]="clearTimeAutocompleteControl"
          [onlyNumber]="true"
        ></mh-core-lib-text-input>
        <div
          class="tw-flex tw-justify-between tw-w-[30%] tw-flex-none tw-cursor-pointer tw-items-center tw-bg-stone-100 tw-text-gray-400 tw-font-medium tw-rounded-r-lg tw-px-4 tw-border tw-border-gray-400 tw-border-l-0"
          (click)="timeOpen = !timeOpen"
          cdkOverlayOrigin
          #timeTrigger="cdkOverlayOrigin"
        >
          <span>{{ 'notifications.central.' + timeSelected | translate }}</span>
          <i class="fal fa-chevron-down"></i>
        </div>

        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="timeTrigger"
          [cdkConnectedOverlayOpen]="timeOpen"
          [cdkConnectedOverlayPanelClass]="'!tw-mt-2'"
          (overlayOutsideClick)="timeOpen = false"
        >
          <ul class="tw-bg-stone-100 tw-shadow-1 tw-relative tw-p-3 tw-rounded-r-lg tw-border tw-border-gray-400 tw-w-40">
            <li class="tw-py-1 tw-cursor-pointer" *ngFor="let item of timeTypes" (click)="handleTimeType(item)">
              <button type="button" class="hover:tw-text-main-orange tw-transition-all focus:tw-outline-0">
                <span class="tw-font-[Rubik] tw-font-medium tw-text-gray-400">{{ 'notifications.central.' + item | translate }}</span>
              </button>
            </li>
          </ul>
        </ng-template>
      </article>

      <article class="tw-mb-2">
        <mh-core-lib-tag-input
          [options]="areas"
          [displayChipsFn]="displayChipsFn"
          [displayChipsListFn]="displayChipsFn"
          [allSelected]="false"
          [checkedAllOption]="false"
          [showAutocompleteInput]="true"
          [inputTagFilter]="autocompleteTeamsFilterFn"
          [displayCheckInput]="false"
          [boxOpen]="false"
          [placeholder]="'core-lib.input.dialog_cases.areas' | translate"
          [customClasses]="'!tw-font-[Rubik] !tw-mb-0'"
          [parentCustomClass]="'tw-border-gray-400 !tw-min-h-0'"
          (optionClicked)="handleAreasInput($event)"
        ></mh-core-lib-tag-input>
      </article>

      <article class="tw-mb-5">
        <mh-core-lib-select-input
          [options]="types"
          [displayFn]="displayFnTypes"
          [placeholder]="'core-lib.input.dialog_cases.type' | translate"
          [keyControl]="'case_type'"
          [formGroup]="caseForm"
          [inputId]="'selectInput-1'"
          [customClasses]="'!tw-font-[Rubik] tw-border-gray-400'"
          [iconClass]="'fal fa-chevron-down !tw-text-gray-400'"
          (click)="scrollToBottom()"
        ></mh-core-lib-select-input>
      </article>

      <article>
        <mh-core-lib-select-input
          [options]="priorities"
          [displayFn]="displayFnPriorities"
          [placeholder]="'core-lib.input.dialog_cases.priority' | translate"
          [keyControl]="'priority'"
          [formGroup]="caseForm"
          [inputId]="'selectInput-2'"
          [customClasses]="'!tw-font-[Rubik] tw-border-gray-400'"
          [iconClass]="'fal fa-chevron-down !tw-text-gray-400'"
          (click)="scrollToBottom()"
        ></mh-core-lib-select-input>
      </article>
    </form>

  </div>

</article>

<article class="tw-pb-8 tw-shadow-[0px_0px_9px_#d6d5d5] tw-relative tw-z-10">
  <section class="tw-flex tw-w-full tw-justify-center tw-pt-7 tw-gap-5 tw-mx-auto">
    <mh-core-lib-button
      [isSecondary]="true"
      [text]="'commons.cancel' | translate"
      [customClass]="'!tw-shadow-none'"
      [textClass]="'tw-font-[Rubik]'"
      (clicked)="close()"
    ></mh-core-lib-button>
    <mh-core-lib-button
      [isPrimary]="true"
      [isDisabled]="caseForm.invalid || uploadingFiles"
      [text]="'cases.titles.form' | translate"
      [customClass]="'!tw-shadow-none'"
      [textClass]="'tw-font-[Rubik]'"
      (clicked)="submit()"
    ></mh-core-lib-button>
  </section>
</article>
