import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DialogRef } from '../modal/dialog-ref';
import { DIALOG_DATA } from '../modal/dialog-tokens';
import { CoreLibModalComponent } from '../modal/modal.component';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { OnsiteGuests } from 'app/shared/cases/types/OnsiteGuests.model';
import { CasesService } from 'app/shared/cases';
import { Select, Store } from '@ngxs/store';
import { CustomersState } from 'app/states/customers/customers.state';
import { Team } from 'app/new-fs/gestor-usuarios/teams/model/teams.model';
import { DefaultLanguageState } from 'app/states/default-language/default-language.state';
import { BreakpointService } from 'app/core-lib/services/breakpoints/breakpoint.service';
import { UsersState } from 'app/states/users/users.state';
import { Observable, Subscription } from 'rxjs';
import { User } from 'app/shared/model';
import { ConfigCasesService } from 'app/cases/services/config-cases.service';

@Component({
  selector: 'mh-core-lib-create-case-modal',
  templateUrl: './create-case-modal.component.html',
  styleUrls: ['./create-case-modal.component.scss'],
})
export class CoreLibCreateCaseModalComponent extends CoreLibModalComponent implements OnInit {
  @ViewChild('createCaseContainer') createCaseContainer: ElementRef<HTMLDivElement>;
  @Select(UsersState.currentUsers) currentUsers$: Observable<any>;

  subs: Subscription;
  todayDate = new Date()
    .toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' })
    .split('/')
    .reverse()
    .join('-');
  types = [];
  priorities = [];
  states = [];
  productItemType = [];
  areas = [];
  teams: Team[] = [];
  onsiteGuests: OnsiteGuests[] = [];
  caseForm: FormGroup;
  assignOptions: Team[] | User[] = [];
  assignTypes: string[] = ['person', 'team'];
  assignOpen = false;
  assignSelected = 'person';
  timeTypes: string[] = ['minutes', 'hours', 'days', 'date'];
  timeOpen = false;
  timeSelected = 'hours';
  uploadingFiles = false;
  clearAssignedAutocompleteControl: string;
  clearTimeAutocompleteControl: string;
  private OPEN_STATE = { id: 1 };
  currentCustomerId = this.store.selectSnapshot(CustomersState).currentCustomer.id;
  currentLanguage = this.store.selectSnapshot(DefaultLanguageState).code;

  displayFnTypes;
  displayFnPriorities;
  displayChipsFn;
  mentionState = [];
  maxTitleLength = 500;
  maxDescriptionLength = 3000;
  flagConditionalExpired = false;

  autocompleteFilterFn = (options, value) => {
    return options.filter((option: any) => {
      return (
        option.first_name.toLowerCase().includes(value.toLowerCase()) ||
        `${option.first_name.toLowerCase()} ${option.last_name.toLowerCase()}`.includes(value.toLowerCase()) ||
        option.email.toLowerCase().includes(value.toLocaleLowerCase())
      );
    });
  };

  autocompleteTeamsFilterFn = (options, value) => {
    if (options && options.length > 0) {
      return options.filter((option: any) => {
        return option.name.toLowerCase().includes(value.toLowerCase() || value);
      });
    }
  };

  autocompleteOnsiteGuestsFilterFn = (options, value) => {
    if (options && options.length > 0) {
      return options.filter((option: any) => {
        return (
          option.guest_name.toLowerCase().includes(value.toLowerCase()) ||
          `${option.guest_name.toLowerCase()} ${option.guest_last_name.toLowerCase()}`.includes(value.toLowerCase()) ||
          option.guest_room.toLowerCase().includes(value.toLowerCase())
        );
      });
    }
  };

  displayUsersOptions = (option) => {
    return `${option.first_name} ${option.last_name} - ${option?.company_position}`;
  };

  displayTeamsOptions = (option) => {
    return option.name;
  };

  displayUserEmailOptions = (option) => {
    return option.email;
  };

  displayOnsiteGuestsOptions = (option) => {
    const checkout = this.datePipe.transform(option.guest_checkout, 'dd MMMM yyyy');
    return `${option.guest_name} ${option.guest_last_name} - ${option.guest_room} - Check out ${checkout}`;
  };

  constructor(
    protected dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private casesService: CasesService,
    private store: Store,
    private breakpointService: BreakpointService,
    private configCasesService: ConfigCasesService,
  ) {
    super(dialogRef, data);
    this.dialogRef.emitEvent(true);
  }

  ngOnInit(): void {
    this.flagConditionalExpired = this.configCasesService.getFlagExpired();
    this.getUsers();
    this.getTeams();
    this.setData();
    this.setForm();
  }

  setForm() {
    this.caseForm = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(this.maxTitleLength)]],
      description: ['', [Validators.required, Validators.maxLength(this.maxDescriptionLength)]],
      state: [this.OPEN_STATE, [Validators.required]],
      priority: ['', [Validators.required]],
      case_type: ['', [Validators.required]],
      author: [this.currentUser],
      assigned: [null, [Validators.required]],
      simple_related_areas: [null, [Validators.required]],
      guest_room: [null],
      time_type: this.fb.group({
        type_time: [this.timeSelected.toUpperCase(), [Validators.required]],
        total: [null, this.timeSelected === 'date' || this.flagConditionalExpired ? [Validators.required] : null],
        date: [null, this.timeSelected === 'date' ? [Validators.required] : null],
      }),
      attachments: [null],
      is_of_team: [false],
    });
    this.setItem();
  }

  setItem() {
    let productItem: any;
    if (this.isNps) {
      productItem = {
        item_id: this.item.answer.id,
        product_id: this.currentProductId, //1 followup / 2 onsite
        type: { id: 1 },
      };
    }
    if (this.isReview) {
      productItem = {
        item_id: this.item.id,
        product_id: 3,
        type: { id: 2 },
      };
    }
    if (this.isPrestayPrecheckin) {
      productItem = {
        item_id: this.item.checkin_id,
        product_id: 4,
        type: { id: 3 },
      };
    }
    if (this.isPrestayOrder) {
      productItem = {
        item_id: this.item.order.id,
        product_id: 4,
        type: { id: 4 },
      };
    }

    this.caseForm.addControl('product_item', new FormControl(productItem));
  }

  setData() {
    this.types = this.data.types;
    this.priorities = this.data.priorities;
    this.productItemType = this.data.productItemType;
    this.states = this.data.states;
    this.areas = this.data.areas;
    this.onsiteGuests = this.data.onsiteGuests;
    this.displayFnTypes = this.data.translatesPipe.types;
    this.displayFnPriorities = this.data.translatesPipe.priorities;
    this.displayChipsFn = this.displayTeamsOptions;
  }

  getUsers() {
    this.assignOptions = this.store.selectSnapshot(UsersState.usersForCases);
  }

  async getTeams() {
    try {
      const resp = await this.casesService.getTeams(this.currentCustomerId).toPromise();
      if (resp) {
        this.teams = resp;
      }
    } catch (error) {
      console.error(error);
    }
  }

  setMentionData() {
    const value = this.caseForm?.value?.description;
    const matches = value.match(/\@\[(.*?)\]/g) || [];
    matches.forEach((match) => {
      const mention = this.mentionState.find((option) => option.key === match);
      if (mention) {
        this.caseForm.value.description = this.caseForm?.value?.description.replace(match, mention.value);
      }
    });
  }

  handleFilesUploaded(files) {
    const savedFiles = this.caseForm.get('attachments').value || [];
    this.caseForm.get('attachments').setValue([...savedFiles, ...files]);
  }

  handleMentionState(event) {
    this.mentionState = event;
  }

  handleAssignOption(option) {
    this.caseForm.get('assigned').setValue(option);
  }

  handleGuestOrRoomOption(event) {
    const guestId = event?.guest_id;
    this.caseForm.get('guest_room').setValue(guestId);
  }

  handleAreasInput(options) {
    const areas = [...options];
    this.caseForm.get('simple_related_areas').setValue(areas);
  }

  handleAssignType($event: string) {
    this.clearAssignedAutocompleteControl = $event;
    this.assignOpen = false;
    this.assignSelected = $event;
    this.assignOptions = $event === 'person' ? this.data.users : this.teams;
    this.caseForm.get('is_of_team').setValue($event === 'team');
  }

  handleEmptyAssignInput($event: string) {
    if (!$event) {
      this.caseForm.get('assigned').reset();
    }
  }

  handleTimeType($event: string) {
    this.clearTimeAutocompleteControl = $event;
    this.timeOpen = false;
    this.timeSelected = $event;
    this.caseForm.get('time_type').get('type_time').setValue($event.toUpperCase());
  }

  handleAssignSelectorClick() {
    if (!this.isMobile) {
      this.assignOpen = !this.assignOpen;
    }
  }

  submit() {
    this.setMentionData();
    if (this.timeSelected === 'date') {
      this.caseForm.get('time_type').get('date').setValue(this.caseForm.get('time_type').get('total').value);
      this.caseForm.get('time_type').get('total').setValue(null);
    }
    this.dialogRef.close(this.caseForm.value);
  }

  close() {
    this.dialogRef.close();
  }

  scrollToBottom() {
    this.createCaseContainer.nativeElement.scrollTop = this.createCaseContainer.nativeElement.scrollHeight;
  }

  get currentUser() {
    return this.data.currentUser;
  }

  get isFromProduct() {
    return this.data?.associated;
  }

  get item() {
    return this.data?.item;
  }

  get isReview() {
    return this.data.associated === 'review';
  }

  get isNps() {
    return this.data.associated === 'nps';
  }

  get isPrestayOrder() {
    return this.data.associated === 'order';
  }

  get isPrestayPrecheckin() {
    return this.data.associated === 'precheckin';
  }

  get currentProductId() {
    return this.data.product;
  }

  get isMobile() {
    return this.breakpointService.isMobile();
  }
}
