<article id="resultSurvey" class="tw-h-full tw-flex tw-flex-col tw-relative">
  <ng-container *ngTemplateOutlet="contactTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="loaderTemplate"></ng-container>

  <section class="tw-w-full tw-flex tw-justify-end tw-pt-4 tw-px-6 xl:tw-pt-3 2xl:tw-pt-4">
    <span class="hover:tw-cursor-pointer" (click)="handleClose()">
      <i class="far fa-times tw-text-3xl tw-text-gray-400"></i>
    </span>
  </section>

  <section class="tw-flex tw-w-[95vw] lg:tw-w-[97vw] xl:tw-w-full tw-p-0 xl:tw-p-3 tw-pt-0 tw-pr-0 tw-overflow-y-auto">
    <div class="tw-basis-2/5 tw-hidden xl:tw-flex tw-flex-col tw-px-5 tw-overflow-y-auto">
      <mh-core-lib-guest-zone [guest]="data?.survey?.guest" [survey]="data?.survey?.answered_survey"></mh-core-lib-guest-zone>
    </div>
    <ng-container *ngTemplateOutlet="surveyTemplate"></ng-container>
  </section>

  <section
    id="footer"
    class="tw-flex tw-w-full tw-justify-center tw-items-center tw-py-6 tw-px-10 tw-shadow-[0px_0px_9px_#d6d5d5]"
    [ngClass]="data?.survey?.answered_survey?.cases.length === 0 ? 'xl:tw-justify-end' : 'xl:tw-justify-between'"
  >
    <div class="tw-hidden xl:tw-flex tw-items-center tw-gap-3" *ngIf="data?.survey?.answered_survey?.cases.length > 0">
      <i class="fad fa-exclamation-triangle fa-lg tw-text-[26px]" style="--fa-primary-color: #000000; --fa-secondary-color: #ffd527; --fa-secondary-opacity: 1;"></i>
      <span class="tw-text-gray-400 tw-text-sm">{{ 'answer_survey.user_with_cases' | translate }}</span>
    </div>

    <div class="tw-flex tw-justify-end tw-items-center tw-gap-3">
      <mh-core-lib-actions-wrapper [slug]="slug">
        <mh-core-lib-button
          class="tw-hidden xl:tw-block"
          [width]="'tw-w-fit'"
          [height]="38"
          [isSecondary]="true"
          [text]="'commons.share' | translate"
          [isDisabled]="downloadingPDF"
          [isDownloading]="downloadingPDF"
          (clicked)="handleOverlayCasesClick(triggerActions)"
          cdkOverlayOrigin
          #triggerActions="cdkOverlayOrigin"
          [ngClass]="{'tw-relative tw-top-[3px]': downloadingPDF }"
        ></mh-core-lib-button>

        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="triggerActionsCardOrigin"
          [cdkConnectedOverlayOpen]="shareActionsOpen"
          [cdkConnectedOverlayOffsetY]="-150"
          [cdkConnectedOverlayPanelClass]="['tw-mr-3', 'trigger-actions-card']"
          (overlayOutsideClick)="shareActionsOpen = false"
        >
          <ul class="tw-bg-white tw-rounded-lg tw-shadow-1 tw-py-1 tw-min-w-[267px] tw-divide-y">
            <li class="tw-cursor-pointer tw-px-4 tw-py-1 tw-font-semibold tw-transition-all">
              <p class="tw-text-main-blue" (click)="goToGuestLink()">{{ 'answer_survey.open_link_for_guest' | translate }}</p>
            </li>
            <li class="tw-cursor-pointer tw-px-4 tw-py-1 tw-font-semibold tw-transition-all">
              <p class="tw-text-main-blue" (click)="copySurveyLinkToClipboard()">{{ 'answer_survey.copy_link_for_internal_use' | translate }}</p>
            </li>
            <li class="tw-cursor-pointer tw-px-4 tw-py-1 tw-font-semibold tw-transition-all" (click)="handleExportPDF(); shareActionsOpen = false;">
              <p class="tw-text-main-blue" [href]="" target="_blank">{{ ('shared.survey-answer-modal.export' | translate) + ' PDF' }}</p>
            </li>
          </ul>
        </ng-template>
      </mh-core-lib-actions-wrapper>

      <mh-core-lib-actions-wrapper [slug]="casesSlug">
        <mh-core-lib-button
          *ngIf="data?.survey?.answered_survey?.cases.length === 0; else showCasesButton"
          [width]="'tw-w-fit'"
          [height]="38"
          [isSecondary]="true"
          [text]="'cases.commons.create_case' | translate"
          (clicked)="handleCreateCase(formattedSurvey)"
        ></mh-core-lib-button>
      </mh-core-lib-actions-wrapper>

      <mh-core-lib-button
        [isSecondary]="true"
        [width]="'tw-w-fit'"
        [height]="38"
        [text]="'answer_survey.contact' | translate"
        [hasImgIcon]="true"
        imgIconUrl="https://mh-statics.s3.sa-east-1.amazonaws.com/uitls/smart-reply-icon-01.svg"
        (clicked)="handleContact()"
      ></mh-core-lib-button>
    </div>
  </section>

  <!-- Overlay and contact template -->
  <div *ngIf="showContact" class="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-z-10"></div>
  <div *ngIf="showContact" class="fixed inset-0 flex items-center justify-center tw-z-20">
    <ng-container *ngTemplateOutlet="contactTemplate"></ng-container>
  </div>

</article>

<ng-template #showCasesButton>
  <mh-core-lib-button
    [width]="'tw-w-fit'"
    [height]="38"
    [isSecondary]="true"
    [text]="'cases.commons.show_cases' | translate"
    (clicked)="cardActionsOpen = !cardActionsOpen"
    cdkOverlayOrigin
    #triggerActionsCard="cdkOverlayOrigin"
  ></mh-core-lib-button>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="triggerActionsCard"
    [cdkConnectedOverlayOpen]="cardActionsOpen"
    [cdkConnectedOverlayPanelClass]="['tw-mr-3', 'trigger-actions-card']"
    (overlayOutsideClick)="cardActionsOpen = false"
    >
    <ul class="tw-bg-white tw-rounded-lg tw-shadow-1 tw-relative tw-py-3 tw-min-w-[220px] tw-divide-y">
      <li *ngFor="let cases of data?.survey?.answered_survey?.cases" class="tw-cursor-pointer tw-px-4 tw-py-1 tw-font-semibold tw-text-main-text hover:tw-text-main-orange tw-transition-all">
        <a [href]="'/cases/'+ ((cases?.closed === true || cases?.closed === undefined) ? 'history' : 'active') +'?id='+cases.public_id" target="_blank">{{cases.title}}</a>
      </li>
      <li class="tw-px-4 tw-py-1 tw-font-semibold tw-text-main-text hover:tw-text-main-orange tw-transition-all">
        <button type="button" (click)="handleCreateCase(formattedSurvey)">{{'cases.titles.form' | translate}}</button>
      </li>
    </ul>
  </ng-template>
</ng-template>

<ng-template #surveyTemplate>
  <div class="tw-basis-3/5 tw-flex tw-flex-col tw-gap-3 tw-justify-start tw-px-0 xl:tw-pl-6 xl:tw-pr-3 current_survey tw-h-[720px] xl:tw-h-full" [ngClass]="{'2xl:!tw-max-h-[600px]': isTight}" id="current_survey">
    <mh-core-lib-tabs class="tw-w-[95vw] xl:tw-w-full" [mainClassName]="'!tw-border-none tw-w-[95vw] xl:tw-w-full tw-overflow-x-auto'" [className]="'!tw-px-8 !tw-py-2 tw-text-center'" [selectedTab]="tabIndex" (tabChanged)="handleChangeTab($event)">
      <mh-core-lib-tab [title]="'answer_survey.info' | translate" *ngIf="isMobile || isTablet || isSmallLaptop">
        <section class="tw-pt-8 tw-overflow-y-auto tw-h-[70vh] tw-max-h-[700px] tw-px-5 xl:tw-px-0 xl:tw-max-h-[500px] 2xl:tw-max-h-full">
          <mh-core-lib-guest-zone [guest]="data?.survey?.guest" [survey]="data?.survey?.answered_survey" [isRestaurant]="varRestaurants"></mh-core-lib-guest-zone>
        </section>
      </mh-core-lib-tab>
      <mh-core-lib-tab [title]="'commons.answers' | translate">
        <section class="tw-pt-8 tw-overflow-y-auto tw-h-[70vh] tw-max-h-[700px] xl:tw-max-h-[500px] 2xl:tw-max-h-full">
          <mh-survey-answer-public
            [surveyId]="data?.survey?.answered_survey?.survey_id"
            [answerId]="data?.survey?.answered_survey?.id"
            [surveyLanguageId]="data.survey.guest.language.id"
            [answersComments]="answersComments"
            (data)="gettingData($event)"
          >
          </mh-survey-answer-public>
        </section>
      </mh-core-lib-tab>
      <mh-core-lib-tab [title]="'navbar.product.default.comments' | translate">
        <section class="tw-pt-8 tw-overflow-y-auto tw-h-[70vh] tw-max-h-[700px] tw-px-5 xl:tw-px-0 xl:tw-max-h-[500px] 2xl:tw-max-h-full">
          <ng-container *ngIf="comments.length === 0; else commentsComponent">
            <section class="tw-flex tw-items-center tw-flex-col">
              <p class="tw-font-bold tw-text-xl tw-text-center lg:tw-text-left">{{ 'answer_survey.no_comments' | translate }}</p>
              <img
                class="tw-w-56 tw-mt-10"
                src="https://mh-statics-dev-new.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/img/empty-messages.png"
                alt="empty messages image"
              />
            </section>
          </ng-container>

          <ng-template #commentsComponent>
            <ng-container *ngFor="let comment of comments">
              <mh-survey-question-public
                [question]="comment.question"
                [answerId]="data?.survey?.answered_survey?.id"
                [language]="data.language"
                [languageId]="data.languageId"
                [surveyLanguageId]="data.survey.guest.language.id"
                [answers]="answersComments"
                [answered]="false"
                [preview]="true"
                [productId]="data.productId"
              ></mh-survey-question-public>
            </ng-container>
          </ng-template>
        </section>
      </mh-core-lib-tab>
      <mh-core-lib-tab [title]="'answer_survey.messages' | translate: { messages: messagesQty ? ('(' + messagesQty + ')') : '' }">
        <section class="tw-pt-4 xl:tw-pt-0 tw-overflow-y-auto tw-h-[70vh] tw-max-h-[700px] tw-px-5 xl:tw-px-0 xl:tw-max-h-[500px] 2xl:tw-max-h-full">
          <mh-survey-messages
            [survey]="data?.survey?.answered_survey"
            [messageRegistered]="messageRegistered"
            (messagesQty)="handleMessagesQty($event)"
          >
          </mh-survey-messages>
        </section>
      </mh-core-lib-tab>
    </mh-core-lib-tabs>
  </div>
</ng-template>

<ng-template #contactTemplate>
  <div
    @fadeSlideInOut
    id="contactTemplate"
    *ngIf="showContact"
    class="tw-w-full tw-h-full xl:tw-h-[79%] tw-bg-white tw-absolute tw-z-10 tw-top-0 xl:tw-top-[21%] tw-left-0 tw-shadown-none xl:tw-shadow-[0px_0px_9px_#d6d5d5]"
  >
    <mh-core-lib-answer-survey
      [customerFrom]="customerFrom"
      [subject]="limitExceeded ? null : subject"
      [survey]="data?.survey"
      [customer]="data?.customer"
      [limitError]="srMonthLimit"
      [details]="data"
      (closeContactTemplate)="handleCloseContactModal($event)">
    </mh-core-lib-answer-survey>
  </div>
</ng-template>



<ng-template #loaderTemplate>
  <article
    id="loaderTemplate"
    *ngIf="showLoader"
    class="tw-w-full tw-h-full tw-bg-white tw-absolute tw-z-10 tw-left-0"
  >
  <section class="tw-px-6 tw-py-24 tw-flex tw-flex-col tw-items-center tw-gap-24 tw-h-full">
    <ng-container *ngIf="!limitExceededError">
      <div>
        <p class="tw-text-center tw-text-2xl tw-font-semibold">{{ 'answer_survey.loader_msg' | translate }}</p>
      </div>
      <div>
        <img class="tw-w-40" src="https://mh-statics-dev-new.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/img/smart-replies-bot.gif" alt="Smart reply loader gif">
      </div>
    </ng-container>
    <mh-core-lib-loader-wrapper
      *ngIf="limitExceededError"
      [type]="'spinner'"
      [loading]="showLoader"
      className="tw-self-center"
      spinnerClassName="tw-text-[50px]">
    </mh-core-lib-loader-wrapper>
  </section>
  </article>
</ng-template>
