<article class="tw-p-4 lg:tw-p-0">
  <section class="tw-flex tw-flex-col tw-gap-1">
    <div class="tw-flex tw-justify-between tw-w-full">
      <p class="tw-font-semibold tw-text-base">{{ guest?.name }} {{ guest?.lastname || guest?.lastName }}</p>
      <div class="tw-flex tw-items-center tw-justify-center">
        <div
          class="tw-text-white tw-px-4 tw-py-1 tw-rounded-xl tw-text-xs tw-font-semibold"
          [ngClass]="{
            'tw-bg-red-500': survey?.nps?.nps === 'detractor',
            'tw-bg-passives': survey?.nps?.nps === 'passive',
            'tw-bg-promoters': survey?.nps?.nps === 'promoter'
          }"
        >
          {{ 'answer_survey.' + survey?.nps?.nps | translate }}
        </div>
      </div>
    </div>
    <p class="tw-text-base tw-cursor-pointer tw-text-sky-500 hover:tw-text-orange-500" (click)="openWhatsapp(guest?.phone)">{{ guest?.phone }}</p>
    <a href="mailto:{{ guest?.email }}" target="_blank" class="tw-text-sky-500"
      ><p class="tw-text-base">{{ guest?.email }}</p></a
    >
    <p class="tw-text-base">{{ guest?.country?.name }}</p>
  </section>

  <hr class="tw-border-t-2 tw-bg-gray-300 tw-my-5">

  <ng-container *ngIf="!isRestaurant">
    <section class="tw-flex tw-flex-col tw-gap-3">
      <div class="tw-flex tw-justify-between" *ngIf="!isRestaurant || isCasino">
        <p class="tw-font-semibold tw-text-base">
          {{ isCasino ?
            ('survey.public_surveys.configurations.edit.reservation_document' | translate) :
            ('commons.fly_code' | translate) 
          }}
        </p>
        <p class="tw-text-base">{{ guest?.reservation_id }}</p>
      </div>
      <ng-container *ngIf="!isRestaurant && !isCasino">
        <div class="tw-flex tw-justify-between">
            <p class="tw-font-semibold tw-text-base">{{ 'commons.checkin' | translate }}</p>
            <p class="tw-text-base">{{ guest?.checkin | date: 'dd/MM/yyyy' }}</p>
        </div>
        <div class="tw-flex tw-justify-between">
            <p class="tw-font-semibold tw-text-base">{{ 'commons.checkout' | translate }}</p>
            <p class="tw-text-base">{{ guest?.checkout | date: 'dd/MM/yyyy' }}</p>
        </div>
        <div class="tw-flex tw-justify-between">
          <p class="tw-font-semibold tw-text-base">{{ 'commons.nights' | translate }}</p>
          <p class="tw-text-base">{{ stay }}</p>
        </div>
        <div class="tw-flex tw-justify-between">
          <p class="tw-font-semibold tw-text-base">{{ 'commons.room' | translate }}</p>
          <p class="tw-text-base">{{ guest?.room }}</p>
        </div>
      </ng-container>
    </section>
    <hr class="tw-border-t-2 tw-bg-gray-300 tw-my-5">
  </ng-container>

  <section class="tw-flex tw-flex-col tw-gap-3">
    <div class="tw-flex tw-justify-between">
        <p class="tw-font-semibold tw-text-base">{{ 'commons.qualify' | translate }}</p>
        <p class="tw-text-base">{{ survey?.satisfaction?.score }} / 5.0</p>
    </div>
    <div class="tw-flex tw-justify-between">
        <p class="tw-font-semibold tw-text-base">{{ 'commons.survey' | translate }}</p>
        <p class="tw-text-base">{{ surveyName }}</p>
    </div>
    <div class="tw-flex tw-justify-between">
        <p class="tw-font-semibold tw-text-base">{{ 'commons.survey_code' | translate }}</p>
        <p class="tw-text-base">{{ survey?.id }}</p>
    </div>
    <div class="tw-flex tw-justify-between">
        <p class="tw-font-semibold tw-text-base">{{ 'commons.origin' | translate }}</p>
        <p class="tw-text-base">{{ survey?.origin }}</p>
    </div>
    <div class="tw-flex tw-justify-between">
        <p class="tw-font-semibold tw-text-base">{{ 'commons.integration_channel' | translate }}</p>
        <p class="tw-text-base">{{ guest?.integration_channel?.source }}</p>
    </div>
    <div class="tw-flex tw-justify-between">
        <p class="tw-font-semibold tw-text-base">{{ 'commons.response_date' | translate }}</p>
        <p class="tw-text-base">{{ survey?.answered_date | date: 'dd/MM/yyyy' }}</p>
    </div>
    <div class="tw-flex tw-justify-between">
        <p class="tw-font-semibold tw-text-base">{{ 'commons.answer_language' | translate }}</p>
        <p class="tw-text-base">{{'languages.iso.' + (survey?.language_answer ? survey?.language_answer?.code : guest?.language?.code) | translate }}</p>
    </div>
  </section>

  <hr class="tw-border-t-2 tw-bg-gray-300 tw-my-5">
</article>
