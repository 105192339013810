import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store'

import { Survey } from "app/shared/model/index";
import { FollowupOnsite } from './followup-onsite.actions'
import { FollowupOnsiteNPSFilterPipe } from 'app/pipes/followup-onsite/followup-onsite-nps-filter.pipe';
import { FollowupOnsiteCountriesFilterPipe } from 'app/pipes/followup-onsite/followup-onsite-countries-filter.pipe'
import { FollowupOnsiteRoomsFilterPipe } from 'app/pipes/followup-onsite/followup-onsite-rooms-filter.pipe'
import { FollowupOnsiteLanguagesFilterPipe } from 'app/pipes/followup-onsite/followup-onsite-languages-filter.pipe'
import { FollowupOnsiteSurveysFilterPipe } from 'app/pipes/followup-onsite/followup-onsite-surveys-filter.pipe'
import { FollowupOnsiteOriginSurveyFilterPipe } from 'app/pipes/followup-onsite/followup-onsite-origin-survey-filter.pipe'
import { FollowupOnsiteOriginNameFilterPipe } from 'app/pipes/followup-onsite/followup-onsite-origin-name-filter.pipe'
import { FollowupOnsiteChannelFilterPipe } from 'app/pipes/followup-onsite/followup-onsite-channel-filter.pipe'
import { FollowupOnsiteCasesFilterPipe } from 'app/pipes/followup-onsite/followup-onsite-cases-filter.pipe'
import {FollowupOnsiteContactedFilterPipe} from 'app/pipes/followup-onsite/followup-onsite-contacted-filter.pipe';

export interface FollowupOnsiteGuestFilter {
  nps: {
    detractors: boolean
    others: boolean
    passives: boolean
    promoters: boolean
  },
  countries: string[]
  rooms: string[]
  languages: string[]
  surveys: any[]
  origin_survey: {
    origin_open_survey: boolean
    origin_email: boolean
  }
  origin_name: string[]
  channels: {id: number, source: string}[]
  cases: any;
  contacted: any;
}

export interface FollowupOnsiteFilterDataGuests {
  countries: string[]
  rooms: string[]
  languages: string[]
  origin_name: string[]
  channels: {id: number, source: string}[]
}

export interface FollowupOnsiteStateModel {
  surveys: any[]
  guests: Survey[]
  filters: FollowupOnsiteGuestFilter
  data_filters: FollowupOnsiteFilterDataGuests
  layout_view: 'list-view' | 'card-view'
}

export const DEFAULT_FILTERS = {
  nps: {
    detractors: false,
    others: false,
    passives: false,
    promoters: false,
  },
  countries: null,
  rooms: null,
  languages: null,
  surveys: null,
  origin_survey: {
    origin_open_survey: false,
    origin_email: false
  },
  origin_name: null,
  channels: null,
  cases: {
    created: false,
    non_created: false
  },
  contacted: {
    contacted: false,
    non_contacted: false
  }
}

const DEFAULT_DATA_FILTERS = {
  countries: null,
  rooms: null,
  languages: null,
  origin_name: null,
  channels: null,
}

const followupOnsiteNPSFilterPipe = new FollowupOnsiteNPSFilterPipe()
const followupOnsiteCountriesFilterPipe = new FollowupOnsiteCountriesFilterPipe()
const followupOnsiteRoomsFilterPipe = new FollowupOnsiteRoomsFilterPipe()
const followupOnsiteLanguagesFilterPipe = new FollowupOnsiteLanguagesFilterPipe()
const followupOnsiteSurveysFilterPipe = new FollowupOnsiteSurveysFilterPipe()
const followupOnsiteOriginSurveyFilterPipe = new FollowupOnsiteOriginSurveyFilterPipe()
const followupOnsiteOriginNameFilterPipe = new FollowupOnsiteOriginNameFilterPipe()
const followupOnsiteChannelFilterPipe = new FollowupOnsiteChannelFilterPipe()
const followupOnsiteCasesFilterPipe = new FollowupOnsiteCasesFilterPipe()
const followupOnsiteContactedFilter = new FollowupOnsiteContactedFilterPipe()

@State<FollowupOnsiteStateModel>({
  name: 'followup_onsite',
  defaults: {
    surveys: null,
    guests: null,
    filters: DEFAULT_FILTERS,
    data_filters: DEFAULT_DATA_FILTERS,
    layout_view: 'list-view'
  }
})
@Injectable()
export class FollowupOnsiteState {

  @Selector()
  static surveys(state: FollowupOnsiteStateModel) {
    return state.surveys
  }

  @Selector()
  static guestsSorted(state: FollowupOnsiteStateModel) {
    return followupOnsiteNPSFilterPipe.transform(
      followupOnsiteCountriesFilterPipe.transform(
        followupOnsiteRoomsFilterPipe.transform(
          followupOnsiteLanguagesFilterPipe.transform(
            followupOnsiteSurveysFilterPipe.transform(
              followupOnsiteOriginSurveyFilterPipe.transform(
                followupOnsiteOriginNameFilterPipe.transform(
                  followupOnsiteChannelFilterPipe.transform(
                    followupOnsiteCasesFilterPipe.transform(
                      followupOnsiteContactedFilter.transform(state.guests, state.filters)
                      , state.filters)
                    , state.filters)
                  , state.filters)
                , state.filters)
              , state.filters)
            , state.filters)
          , state.filters)
        , state.filters)
      , state.filters)
  }

  @Selector()
  static guestsTotal(state: FollowupOnsiteStateModel) {
    return state.guests
  }

  @Selector()
  static filters(state: FollowupOnsiteStateModel) {
    return state.filters
  }

  @Selector()
  static dataFilters(state: FollowupOnsiteStateModel) {
    return state.data_filters
  }

  @Selector()
  static layoutView(state: FollowupOnsiteStateModel) {
    return state.layout_view
  }

  constructor(
  ){}

  @Action(FollowupOnsite.StoreSurveys)
  storeSStoreSurveys(context: StateContext<FollowupOnsiteStateModel>, action: FollowupOnsite.StoreSurveys) {
    context.patchState({
      surveys: action.surveys,
    })
  }

  @Action(FollowupOnsite.StoreGuestsList)
  storeGuestsList(context: StateContext<FollowupOnsiteStateModel>, action: FollowupOnsite.StoreGuestsList) {
    context.patchState({
      guests: action.guests,
    })
  }

  @Action(FollowupOnsite.GuestListClear)
  guestListClear(context: StateContext<FollowupOnsiteStateModel>) {
    context.patchState({
      guests: null
    })
  }

  @Action(FollowupOnsite.StoreFilterDataGuests)
  storeFilterDataGuests(context: StateContext<FollowupOnsiteStateModel>, action: FollowupOnsite.StoreFilterDataGuests) {
    context.patchState({
      data_filters: action.data,
    })
  }

  @Action(FollowupOnsite.ChangeNPSFilter)
  changeNPSFilter(context: StateContext<FollowupOnsiteStateModel>, action: FollowupOnsite.ChangeNPSFilter) {
    const filters = { ...context.getState().filters }
    filters.nps = action.nps
    context.patchState({
      filters,
    })
  }

  @Action(FollowupOnsite.ChangeLanguageFilter)
  changeLanguageFilter(context: StateContext<FollowupOnsiteStateModel>, action: FollowupOnsite.ChangeLanguageFilter) {
    const filters = { ...context.getState().filters }
    filters.languages = action.languages
    context.patchState({
      filters,
    })
  }

  @Action(FollowupOnsite.ChangeCountriesFilter)
  changeCountriesFilter(context: StateContext<FollowupOnsiteStateModel>, action: FollowupOnsite.ChangeCountriesFilter) {
    const filters = { ...context.getState().filters }
    filters.countries = action.countries
    context.patchState({
      filters,
    })
  }

  @Action(FollowupOnsite.ChangeRoomsFilter)
  changeRoomsFilter(context: StateContext<FollowupOnsiteStateModel>, action: FollowupOnsite.ChangeRoomsFilter) {
    const filters = { ...context.getState().filters }
    filters.rooms = action.rooms
    context.patchState({
      filters,
    })
  }

  @Action(FollowupOnsite.ChangeSurveyFilter)
  changeSurveyFilter(context: StateContext<FollowupOnsiteStateModel>, action: FollowupOnsite.ChangeSurveyFilter) {
    const filters = { ...context.getState().filters }
    filters.surveys = action.surveys
    context.patchState({
      filters,
    })
  }

  @Action(FollowupOnsite.ChangeOriginSurveyFilter)
  changeOriginSurveyFilter(context: StateContext<FollowupOnsiteStateModel>, action: FollowupOnsite.ChangeOriginSurveyFilter) {
    const filters = { ...context.getState().filters }
    filters.origin_survey = action.origin_survey
    context.patchState({
      filters,
    })
  }

  @Action(FollowupOnsite.ChangeOriginNameFilter)
  changeOriginNameFilter(context: StateContext<FollowupOnsiteStateModel>, action: FollowupOnsite.ChangeOriginNameFilter) {
    const filters = { ...context.getState().filters }
    filters.origin_name = action.origin_name
    context.patchState({
      filters,
    })
  }

  @Action(FollowupOnsite.ChangeChannelFilter)
  changeChannelFilter(context: StateContext<FollowupOnsiteStateModel>, action: FollowupOnsite.ChangeChannelFilter) {
    const filters = { ...context.getState().filters }
    filters.channels = action.channels
    context.patchState({
      filters,
    })
  }

  @Action(FollowupOnsite.ChangeCasesFilter)
  changeCasesFilter(context: StateContext<FollowupOnsiteStateModel>, action: FollowupOnsite.ChangeCasesFilter) {
    const filters = { ...context.getState().filters }
    filters.cases = action.cases
    context.patchState({
      filters,
    })
  }

  @Action(FollowupOnsite.ChangeContactedFilter)
  ChangeContactedFilter(context: StateContext<FollowupOnsiteStateModel>, action: FollowupOnsite.ChangeContactedFilter) {
    const filters = { ...context.getState().filters }
    filters.contacted = action.contacted;
    context.patchState({
      filters,
    })
  }

  @Action(FollowupOnsite.FilterClear)
  filterClear(context: StateContext<FollowupOnsiteStateModel>) {
    context.patchState({
      filters: DEFAULT_FILTERS
    })
  }

  @Action(FollowupOnsite.ChangeLayoutView)
  changeLayoutView(context: StateContext<FollowupOnsiteStateModel>, action: FollowupOnsite.ChangeLayoutView) {
    context.patchState({
      layout_view: action.view
    })
  }
}
